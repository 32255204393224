<template>
  <v-dialog
    :id="id"
    v-model="show"
    :width="width"
    :persistent="persistent"
    @keydown.esc="close"
    @click:outside="close"
  >
    <v-card>
      <v-card-title class="pl-4 pr-4">
        <v-btn
          color="grey"
          class="text-none text-subtitle-1 font-weight-medium"
          @click="close"
          text
        >
          {{ $ml.get("cancel") }}
        </v-btn>
        <v-spacer />
        <span v-if="$vuetify.breakpoint.smAndUp">
          {{ $ml.get("crop_image") }}
        </span>
        <v-spacer />
        <v-btn
          color="accent"
          class="text-none elevation-0 text-subtitle-1 font-weight-medium"
          @click="confirmAction"
        >
          {{ $ml.get("crop") }}
        </v-btn>
      </v-card-title>
      <v-card-text class="text-md-body-1 px-4 mt-3">
        <cropper
          ref="cropper"
          class="cropper-component"
          :recizeImage="true"
          :src="image"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "CropImageDialog",
  props: {
    id: { type: String, required: true },
    width: { type: String, default: "700" },
    persistent: { type: Boolean, default: false },
    show: { type: Boolean, required: true },
    image: { type: String },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    confirmAction() {
      const { canvas } = this.$refs.cropper.getResult();
      canvas.toBlob((blob) => {
        this.$emit("crop", blob);
      });
    },
  },
};
</script>
<style>
.cropper-component {
  max-height: 400px;
  overflow: hidden;
}
</style>
