<template>
  <v-card flat tile color="grey lighten-4">
    <v-row align="center" justify="center" class="pt-3 pb-5">
      <div class="d-flex flex-column justify-center">
        <v-subheader class="text-none">
          {{ $ml.get("upload_image_title") }}
        </v-subheader>
        <v-btn
          color="primary"
          dark
          @click="handleImageImport"
          class="text-none text-body-1 font-weight-medium"
        >
          <v-icon left>mdi-upload</v-icon>
          {{ buttonText }}
        </v-btn>
        <!-- Campo de input oculto que será ativado ao clicar no botão -->
        <input
          :id="imageUploadId"
          class="d-none"
          type="file"
          :accept="accept"
          @change="onFileChanged($event)"
        />
      </div>
    </v-row>
  </v-card>
</template>
<script>
export default {
  name: "FileUploader",
  props: {
    accept: { type: String, default: "image/*" },
    buttonText: { type: String, required: true },
    title: { type: String, required: true },
    imageUploadId: { type: String, required: true },
  },
  data: () => ({
    image: null,
  }),

  methods: {
    handleImageImport() {
      let fileUpload = document.getElementById(this.imageUploadId);
      if (fileUpload != null) {
        fileUpload.click();
      }
    },
    onFileChanged({ target }) {
      this.image = target.files[0];
      this.$emit("change", this.image);
    },
  },
};
</script>
