<template>
  <v-menu
    transition="scale-transition"
    offset-y
    min-width="auto"
    :close-on-content-click="false"
    v-model="showMenu"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        @change="
          newColor = $event;
          showMenu = true;
        "
        :value="color"
        v-bind="attrs"
        v-on="on"
        outlined
        readonly
        hide-details
        class="mt-4"
      >
        <template v-slot:label>
          <div class="pb-2">{{ label }}</div>
        </template>
        <template v-slot:append>
          <v-icon v-text="'mdi-square-rounded'" :color="color" />
        </template>
      </v-text-field>
    </template>
    <v-card>
      <v-card-text>
        <v-color-picker
          v-model="newColor"
          show-swatches
          hide-mode-switch
          width="300"
          swatches-max-height="150px"
          mode="hexa"
          flat
        ></v-color-picker>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-btn
          block
          color="secondary"
          text
          @click="$emit('restoreValue')"
          class="body-1 font-weight-medium text-none mx-auto"
          small
        >
          {{ $ml.get("restore_default_color") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  name: "InputColor",
  props: {
    color: { type: String, required: true },
    label: { type: String, required: true },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    newColor: {
      get() {
        return this.color;
      },
      set(value) {
        this.$emit("action", value);
      },
    },
  },
};
</script>
