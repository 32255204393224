<template>
  <v-container>
    <v-card outlined class="overflow-hidden">
      <Alert
        dense
        type="info"
        :showAction="true"
        :actionText="$ml.get('view_in_full_screen')"
        @action="dialog = true"
      >
        <span>{{ $ml.get("login_preview_info") }}</span>
      </Alert>
      <div :style="backgroundImagePreviewMobile" class="pb-2">
        <div class="d-flex flex-column align-center">
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <h3 class="domain mb-4 mt-8" v-else-if="!logoUrl">
            {{ main_domain }}
          </h3>
          <Alert
            dense
            type="error"
            icon="mdi-alert"
            :text="false"
            :showAction="false"
            v-else-if="logoErrorMessage"
            text-color="text-white"
          >
            {{ logoErrorMessage }}
          </Alert>
          <img
            v-else
            :src="logoUrl"
            :height="validateDimension(logoHeight, 'height')"
            :width="validateDimension(logoWidth, 'width')"
          />
          <h1 class="welcome" :style="`color: ${textColor}`">
            {{ $ml.get("title_login_preview") }}
          </h1>
          <h2 class="init-session" :style="`color: ${textColor}`">
            {{ $ml.get("init_session") }}
          </h2>

          <div class="form-card">
            <div class="mb-4 d-flex flex-column">
              <label class="label">{{ $ml.get("email") }}</label>
              <input
                readonly
                class="inputs"
                :placeholder="$ml.get('type_email')"
              />
              <label class="label">{{ $ml.get("password") }}</label>
              <input
                class="inputs"
                :placeholder="$ml.get('type_password')"
                readonly
              />
            </div>
            <div class="buttons mb-4" :style="{ background: primaryColor }">
              {{ $ml.get("access") }}
            </div>
            <div class="buttons" :style="{ background: secondaryColor }">
              {{ $ml.get("change_password") }}
            </div>
          </div>
        </div>

        <div style="text-align: center">
          <p :style="`color: ${textColor}`">
            {{ $ml.get("powered_by_suite") }}
            <a
              href="https://conectasuite.com/"
              target="_blank"
              class="green-text"
              >{{ $ml.get("more_info") }}</a
            >
          </p>
          <div class="footer-img">
            <img class="mr-5" :src="suiteLogo" style="width: 90px" />
            <img :src="controlLogo" style="height: 30px" />
          </div>
        </div>
      </div>
    </v-card>
    <v-dialog
      v-model="dialog"
      fullscreen
      transition="dialog-bottom-transition"
      class="overflow-hidden"
    >
      <v-card tile>
        <v-toolbar flat color="primary" class="">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title class="text-truncate white--text">{{
            $ml.get("preview")
          }}</v-toolbar-title>
        </v-toolbar>
        <div :style="backgroundImagePreviewFullScreen" class="max-viewport">
          <div class="d-flex flex-column align-center">
            <v-progress-circular
              v-if="loading"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <h3 class="domain" v-else-if="!logoUrl">{{ main_domain }}</h3>
            <Alert
              dense
              type="error"
              icon="mdi-alert"
              :text="false"
              :showAction="false"
              v-else-if="logoErrorMessage"
              text-color="text-white"
            >
              {{ logoErrorMessage }}
            </Alert>
            <img
              v-else
              :src="logoUrl"
              :height="validateDimension(logoHeight, 'height')"
              :width="validateDimension(logoWidth, 'width')"
            />
            <h1 class="welcome" :style="`color: ${textColor}`">
              {{ $ml.get("title_login_preview") }}
            </h1>
            <h2 class="init-session" :style="`color: ${textColor}`">
              {{ $ml.get("init_session") }}
            </h2>

            <div class="form-card">
              <div class="mb-4 d-flex flex-column">
                <label class="label">{{ $ml.get("email") }}</label>
                <input
                  readonly
                  class="inputs"
                  :placeholder="$ml.get('type_email')"
                />
                <label class="label">{{ $ml.get("password") }}</label>
                <input
                  class="inputs mb-0"
                  :placeholder="$ml.get('type_password')"
                  readonly
                />
              </div>
              <div class="buttons mb-5" :style="{ background: primaryColor }">
                {{ $ml.get("access") }}
              </div>
              <div class="buttons mb-4" :style="{ background: secondaryColor }">
                {{ $ml.get("change_password") }}
              </div>
            </div>
          </div>

          <div style="text-align: center">
            <p :style="`color: ${textColor}`">
              {{ $ml.get("powered_by_suite") }}
              <a
                href="https://conectasuite.com/"
                target="_blank"
                class="green-text"
                >{{ $ml.get("more_info") }}</a
              >
            </p>
            <div class="footer-img">
              <img class="mr-5" :src="suiteLogo" style="width: 90px" />
              <img :src="controlLogo" style="height: 30px" />
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { maxLogoDimension, minLogoDimension } from "@/helpers/variables";
export default {
  name: "LoginPagePreview",
  props: {
    logoUrl: { type: String },
    backgroundImageUrl: { type: String },
    main_domain: { type: String },
    loading: { type: Boolean },
    primaryColor: { type: String, required: true },
    secondaryColor: { type: String, required: true },
    textColor: { type: String, required: false },
    logoHeight: { type: [Number, String] },
    logoWidth: { type: [Number, String] },
    logoErrorMessage: { type: String },
  },

  data: () => ({
    controlLogo: require("@/assets/appslogos/full/conecta_control_full.png"),
    suiteLogo: require("@/assets/appslogos/full/conecta_suite_full.png"),
    icons: ["mdi-rewind", "mdi-play", "mdi-fast-forward"],
    dialog: false,
  }),
  computed: {
    backgroundImagePreviewFullScreen() {
      return {
        background: `url(${this.backgroundImageUrl})`,
        "background-repeat": "no-repeat",
        "background-position": "center",
        "background-size": "cover",
      };
    },
    backgroundImagePreviewMobile() {
      return {
        background: `url(${this.backgroundImageUrl})`,
        "background-repeat": "no-repeat",
        "background-position": "center",
        "background-size": "100% 100%",
        width: "100%",
        height: "100%",
      };
    },
  },
  methods: {
    crop() {
      const { canvas } = this.$refs.cropper.getResult();
      canvas.toBlob((blob) => {
        this.$emit("cropImage", blob);
        this.showCropperDialog = false;
      });
    },
    validateDimension(dimension, field) {
      if (dimension < minLogoDimension) {
        return field === "width" ? "" : minLogoDimension;
      }

      return dimension > maxLogoDimension ? maxLogoDimension : dimension;
    },
  },
};
</script>

<style>
.domain {
  font-size: 26px;
  font-weight: 400;
  color: #2c3e50;
}

.init-session {
  margin-bottom: 25px;
  font-family: "Open Sans" !important;
  -webkit-font-smoothing: antialiased;
  color: #555;
  font-size: 18px;
  font-weight: 400;
}

.welcome {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 38px;
  font-weight: 300 !important;
  line-height: 41.8px;
  color: #555;
  font-weight: 400;
}

.form-card {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  background-color: #f7f7f7;
  padding: 50px 35px 50px;
  margin: 0 auto 25px;
  width: 400px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.label {
  margin: 0 0 5px 5px;
  color: #444;
  font-size: 15px;
  font-weight: 300;
  line-height: 21.8px;
}

.inputs {
  padding: 10px 15px;
  border: 1px solid #dce4ec;
  margin-bottom: 10px;
  background-color: white;
}

.buttons {
  font-size: 15px;
  padding: 10px 15px;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  width: 100%;
  color: white;
}

.footer-text {
  font-size: 15px;
  color: #2c3e50;
  line-height: 1.4px;
}

.footer-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.control {
  font-size: 11px;
  margin-left: 4px;
}

.green-text {
  color: #18bc9c !important;
  text-decoration: none;
}

.blue-text {
  color: #4c8efa;
}

.max-viewport {
  width: 100vw;
  height: 100vh;
}
</style>
