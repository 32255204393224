<template>
  <v-container class="mt-0">
    <v-card v-if="!loading" :loading="pageLoading" outlined>
      <v-card-title>{{ $ml.get("pers_login_title") }}</v-card-title>
      <v-card-subtitle>{{ $ml.get("pers_login_subtitle") }}</v-card-subtitle>
      <v-card-text class="mt-2">
        <v-row>
          <v-col>
            <v-card outlined class="mt-3 px-5 pt-3 pb-7">
              <v-card-title
                class="black--text text-body-1 font-weight-medium px-0 pb-0"
              >
                {{ $ml.get("company_logo") }}
              </v-card-title>

              <v-subheader class="px-0 pt-0 mb-5 text-none">
                {{ $ml.get("logo_description") }}
              </v-subheader>
              <FileUploader
                v-if="!imagePreview"
                imageUploadId="imagePreview"
                @change="
                  file = $event;
                  showCropperDialog = true;
                "
                type="image"
                :title="$ml.get('upload_image_title')"
                :buttonText="$ml.get('upload_image')"
              />
              <div v-else>
                <v-file-input
                  v-model="file"
                  outlined
                  :rules="logoRules"
                  @update:error="logoError = $event"
                  accept="image/*"
                  prepend-icon=""
                  color="primary"
                  :label="$ml.get('logo_image')"
                  clear-icon="mdi-trash-can-outline"
                  clearable
                >
                  <template #append>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          @click="showCropperDialog = true"
                          v-text="'mdi-crop'"
                          color="primary"
                        />
                      </template>
                      <span>{{ $ml.get("crop_image") }}</span>
                    </v-tooltip>
                  </template>
                </v-file-input>
                <v-row>
                  <v-col>
                    <v-btn
                      block
                      text
                      color="primary"
                      small
                      class="text-none text-body-2 font-weight-medium"
                      @click="showCropperDialog = true"
                    >
                      <v-icon left>mdi-crop</v-icon>
                      {{ $ml.get("crop_image") }}
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      v-if="image"
                      block
                      text
                      color="accent"
                      small
                      @click="removeLogo"
                      class="text-none text-body-2 font-weight-medium"
                    >
                      <v-icon left>mdi-reload</v-icon>
                      {{ $ml.get("undo_logo_changes") }}
                    </v-btn>
                    <v-btn
                      v-else-if="!file"
                      block
                      text
                      color="red"
                      small
                      @click="removeLogo"
                      class="text-none text-body-2 font-weight-medium"
                    >
                      <v-icon left>mdi-trash-can-outline</v-icon>
                      {{ $ml.get("remove_current_logo") }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row no-gutters class="px-0 mx-0 mt-5" gutter="4">
                  <v-col class="pr-0 mr-2">
                    <v-text-field
                      v-model="logoHeight"
                      :label="$ml.get('height')"
                      :placeholder="$ml.get('logo_height_placeholder')"
                      :rules="[(value) => validateValues(value, 'height')]"
                      type="number"
                      :min="minLogoDimension"
                      :max="maxLogoDimension"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col class="pl-0 ml-2">
                    <v-text-field
                      v-model="logoWidth"
                      :label="$ml.get('width')"
                      :placeholder="heightPlaceholder"
                      :persistent-placeholder="true"
                      :rules="[(value) => validateValues(value, 'width')]"
                      :error="validationError.width"
                      @focus="
                        handleHeightPlaceholder(
                          $ml.get('logo_width_placeholder')
                        )
                      "
                      @blur="handleHeightPlaceholder($ml.get('automatic'))"
                      type="number"
                      :min="minLogoDimension"
                      :max="maxLogoDimension"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-btn
                  :disabled="!editedLogoDimensions"
                  block
                  text
                  color="secondary lighten-2"
                  small
                  class="mb-5 text-none text-body-2 font-weight-medium"
                  @click="resetDimensions"
                >
                  <v-icon left>mdi-resize</v-icon>
                  {{ $ml.get("reset_image_dimensions") }}
                </v-btn>
              </div>
            </v-card>
            <v-card outlined class="mt-3 px-5 pt-3 pb-7">
              <v-card-title
                class="black--text text-body-1 font-weight-medium px-0 pb-0"
              >
                {{ $ml.get("background_image") }}
              </v-card-title>

              <v-subheader class="px-0 pt-0 text-none">
                {{ $ml.get("background_image_description") }}
              </v-subheader>
              <v-subheader
                class="px-0 pt-0 mb-8 text-none d-flex flex-column align-start"
              >
                <span
                  ><strong>{{ $ml.get("recommendations") }}</strong></span
                >
                <span>{{ $ml.get("image_resolution_recomendation") }}</span>
                <span>{{ $ml.get("image_color_recomendation") }}</span>
              </v-subheader>
              <FileUploader
                v-if="!backgroundImageFile && !company.background_image"
                imageUploadId="backgroundImageFile"
                @change="backgroundImageFile = $event"
                type="image"
                :title="$ml.get('upload_image_title')"
                :buttonText="$ml.get('upload_image')"
              />
              <div v-else>
                <v-file-input
                  v-model="backgroundImageFile"
                  outlined
                  @update:error="logoError = $event"
                  accept="image/*"
                  prepend-icon=""
                  color="primary"
                  :label="$ml.get('logo_image')"
                  clear-icon="mdi-trash-can-outline"
                  clearable
                >
                </v-file-input>
              </div>
              <v-row
                class="d-flex flex-column align-center"
                v-if="company.background_image"
              >
                <v-col cols="4">
                  <v-btn
                    text
                    color="red"
                    small
                    @click="removeBackgroundImage"
                    class="text-none text-body-2 font-weight-medium"
                  >
                    <v-icon left>mdi-trash-can-outline</v-icon>
                    {{ $ml.get("remove_current_background_image") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
            <v-card outlined class="mt-5 px-5 pt-3 pb-7 mb-3">
              <v-card-title
                class="black--text text-body-1 font-weight-medium px-0 pb-0"
              >
                {{ $ml.get("screen_colors") }}
              </v-card-title>
              <v-subheader class="px-0 pt-0 mb-3 text-none">
                {{ $ml.get("screen_color_description") }}
              </v-subheader>
              <InputColor
                :color="primaryColor"
                :label="$ml.get('primary_color')"
                @action="setPrimaryColor"
                @restoreValue="setDefaultPrimaryColor"
                key="primary"
              />
              <InputColor
                :color="secondaryColor"
                :label="$ml.get('secondary_color')"
                @action="setSecondaryColor"
                @restoreValue="setDefaultSecondaryColor"
                key="secondary"
              />
              <InputColor
                :color="textColors"
                :label="$ml.get('text_color')"
                @action="setTextColor"
                @restoreValue="setDefaultTextColor"
                key="textColor"
              />
            </v-card>
            <v-btn
              color="accent"
              class="text-none"
              :loading="pageLoading"
              :disabled="
                !disableButtons || dimensionsError || logoError || pageLoading
              "
              @click="save"
            >
              {{ $ml.get("save") }}
            </v-btn>
            <v-btn
              class="text-none ml-2"
              text
              :disabled="
                !disableButtons || dimensionsError || logoError || pageLoading
              "
              @click="cancelEdit"
              >{{ $ml.get("cancel") }}</v-btn
            >
          </v-col>

          <v-col>
            <LoginPagePreview
              :key="key"
              :loading="pageLoading"
              :logoUrl="imagePreview"
              :background-image-url="backgroundImagePreview"
              :main_domain="company.main_domain"
              :primaryColor="primaryColor"
              :secondaryColor="secondaryColor"
              :textColor="textColor"
              @deleteLogo="removeLogo"
              :logoWidth="logoWidth"
              :logoHeight="logoHeight"
              :logoErrorMessage="
                logoErrorMessage.width || logoErrorMessage.height
              "
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!--  -->
    <ConfirmationDialog
      :target="$ml.get('change_existing_infos')"
      :show="showConfirmDialog"
      @close="showConfirmDialog = false"
      @ok="uploadFile"
      :loading="pageLoading"
      :reversible="true"
    />
    <ConfirmationDialog
      :target="$ml.get('remove_company_logo')"
      :show="showConfirmRemoveDialog"
      @close="showConfirmRemoveDialog = false"
      @ok="removeLogoFromStorage"
      :loading="pageLoading"
      :reversible="true"
    />
    <ConfirmationDialog
      :target="$ml.get('remove_background_image')"
      :show="showConfirmRemoveBackgroundDialog"
      @close="showConfirmRemoveBackgroundDialog = false"
      @ok="removeBackgroundImageFromStorage"
      :loading="pageLoading"
      :reversible="true"
    />
    <CropImageDialog
      id="crop-image"
      :key="key"
      :image="cropImagePreview"
      :show="showCropperDialog"
      @close="showCropperDialog = false"
      @crop="setCroppedImage"
    />
    <HeaderDialog
      :show="feedbackDialog"
      :title="$ml.get('rate_your_experience')"
      :subtitle="$ml.get('rate_your_experience_subtitle')"
      width="600"
      readOnly
      customFooter
      :persistent="false"
      @close="feedbackDialog = false"
    >
      <template #body>
        <FeedbackRating
          v-if="feedbackDialog"
          action="customize_login"
          show-dismiss
          rating-class="d-flex justify-start ml-1"
          width="400"
          @close="feedbackDialog = false"
        />
      </template>
    </HeaderDialog>
  </v-container>
</template>
<script>
import InputColor from "@/components/InputColor";
import LoginPagePreview from "@/components/LoginPagePreview";
import CropImageDialog from "@/components/general/CropImageDialog";
import FileUploader from "@/components/general/FileUploader";
import { maxLogoDimension, minLogoDimension } from "@/helpers/variables";
import { setFirebaseEvent } from "@/helpers/registerAnalytics";
import FeedbackRating from "@/components/forms/FeedbackRating";
import { renewFeedback } from "@/helpers/utils";
import {
  DEFAULT_SSO_PRIMARY_COLOR,
  DEFAULT_SSO_SECONDARY_COLOR,
  DEFAULT_SSO_TEXT_COLOR,
} from "@/helpers/sso";

import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "CustomizeLogin",
  components: {
    LoginPagePreview,
    InputColor,
    FileUploader,
    CropImageDialog,
    FeedbackRating,
  },
  data() {
    return {
      file: null,
      backgroundImageFile: null,
      pageLoading: false,
      showConfirmDialog: false,
      showConfirmDialogBackground: false,
      showConfirmRemoveDialog: false,
      showConfirmRemoveBackgroundDialog: false,
      showCropperDialogBackground: false,
      primaryColor: "",
      secondary: "",
      textColor: "",
      height: null,
      width: null,
      data: {},
      key: 0,
      image: null,
      logoError: false,
      validationError: {
        width: false,
        height: false,
      },
      logoRules: [
        (value) =>
          !value || value.size < 2000000 || this.$ml.get("image_size_error"),
      ],
      showCropperDialog: false,
      heightPlaceholder: this.$ml.get("automatic"),
      minLogoDimension,
      maxLogoDimension,
      logoErrorMessage: {
        width: null,
        height: null,
      },
      feedbackDialog: false,
    };
  },

  computed: {
    ...mapGetters([
      "company",
      "loading",
      "currentUserFeedbacks",
      "mainDomain",
      "currentUser",
    ]),

    userHasAlreadySentFeedback() {
      return this.renewFeedback(this.currentUserFeedbacks.customize_login);
    },

    logoWidth: {
      get() {
        return this.width;
      },
      set(value) {
        value = ["", "null"].includes(value) ? null : value;
        this.width = value;
        this.data.logo_width = value;
      },
    },

    logoHeight: {
      get() {
        return this.height;
      },
      set(value) {
        value = ["null"].includes(value) ? null : value;
        this.height = value;
        this.data.logo_height = value;
      },
    },

    payload() {
      return this.data;
    },

    imagePreview() {
      if (this.file || this.image) {
        const url = URL.createObjectURL(this.image);
        return url;
      }

      return this.company.logo;
    },
    backgroundImagePreview() {
      if (this.backgroundImageFile) {
        const url = URL.createObjectURL(this.backgroundImageFile);
        return url;
      }

      return this.company.background_image;
    },
    editedLogoDimensions() {
      const editedWidth = this.company.logo_width !== this.logoWidth;
      const editedHeight = this.company.logo_height !== this.logoHeight;

      return editedWidth || editedHeight;
    },

    dimensionsError() {
      return this.validationError.width || this.validationError.height;
    },

    disableButtons() {
      return (
        this.editedLogoDimensions ||
        this.editedPrimaryColor ||
        this.editedSecondaryColor ||
        this.image ||
        this.backgroundImageFile ||
        this.editedTextColor
      );
    },

    cropImagePreview() {
      if (this.file) {
        const url = URL.createObjectURL(this.file);
        return url;
      }

      return this.company.logo;
    },

    secondaryColor: {
      get() {
        return this.secondary;
      },
      set(color) {
        this.secondary = color;
        this.data.secondary_color = color;
      },
    },
    textColors: {
      get() {
        return this.textColor;
      },
      set(color) {
        this.textColor = color;
        this.data.text_color = color;
      },
    },
    editedPrimaryColor() {
      return this.company.primary_color !== this.primaryColor;
    },
    editedSecondaryColor() {
      return this.company.secondary_color !== this.secondaryColor;
    },
    editedTextColor() {
      return this.company.text_color !== this.textColor;
    },
  },

  methods: {
    ...mapActions(["setSnackbarDefault"]),
    ...mapMutations(["setNewCompanyValue"]),
    renewFeedback,

    setCroppedImage(image) {
      this.image = image;
      this.showCropperDialog = false;
    },
    handleHeightPlaceholder(placeholder) {
      this.heightPlaceholder = placeholder;
    },
    forceUpdate() {
      this.key += 1;
    },
    resetDimensions() {
      this.logoHeight = this.company.logo_height;
      this.logoWidth = this.company.logo_width;
    },
    validateValues(value, field) {
      value = ["", "null"].includes(value) ? null : value;

      if (field === "width" && !value) {
        this.logoErrorMessage[field] = null;
        this.validationError[field] = false;

        return true;
      }

      if (value < minLogoDimension || value > maxLogoDimension) {
        this.validationError[field] = true;

        this.logoErrorMessage[field] = this.$ml.get(
          `description_${field}_error`
        );
        return this.logoErrorMessage[field];
      } else {
        this.logoErrorMessage[field] = null;
        this.validationError[field] = false;
        return true;
      }
    },
    cancelEdit() {
      this.file = null;
      this.backgroundImageFile = null;
      this.image = null;
      this.data = {};
      this.primaryColor = this.company.primary_color;
      this.secondaryColor = this.company.secondary_color;
      this.logoHeight = this.company.logo_height;
      this.logoWidth = this.company.logo_width;
    },

    removeLogoFromStorage() {
      this.showConfirmRemoveDialog = false;
      this.pageLoading = true;

      const storage = getStorage();
      const storageRef = ref(
        storage,
        "customer_logos/" + this.company.main_domain
      );

      deleteObject(storageRef)
        .then(() => {
          const defaultImageHeight = "85";
          this.snackbarCode = "remove_logo_success";
          this.data.logo = null;
          this.data.logo_height = defaultImageHeight;
          this.updateCompany();
        })
        .catch(() => {
          this.setSnackbarDefault({
            message: "remove_logo_error",
            color: "error",
          });

          this.pageLoading = false;
        });
    },
    removeBackgroundImageFromStorage() {
      this.showConfirmRemoveDialog = false;
      this.pageLoading = true;

      const storage = getStorage();
      const storageRef = ref(
        storage,
        "customer_backgrounds/" + this.company.main_domain
      );

      deleteObject(storageRef)
        .then(() => {
          this.snackbarCode = "remove_image_success";
          this.data.background_image = null;
          this.updateCompany();
        })
        .catch(() => {
          this.setSnackbarDefault({
            message: "remove_image_error",
            color: "error",
          });

          this.pageLoading = false;
        });
    },

    removeLogo() {
      if (this.company.logo && !this.image) {
        this.showConfirmRemoveDialog = true;
      }

      this.file = null;
      this.image = null;
    },
    removeBackgroundImage() {
      if (this.company.background_image && !this.backgroundImageFile) {
        this.showConfirmRemoveBackgroundDialog = true;
      }
    },

    uploadFile() {
      this.pageLoading = true;
      const storage = getStorage();
      const uploadPromises = [];

      const handleUpload = (file, path, dataKey) => {
        return new Promise((resolve, reject) => {
          const storageRef = ref(storage, path);
          const uploadTask = uploadBytesResumable(storageRef, file);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Upload is ${progress}% done`);
              if (snapshot.state === "paused") {
                console.log("Upload is paused");
              } else if (snapshot.state === "running") {
                console.log("Upload is running");
              }
            },
            (error) => {
              console.log(error);
              this.setSnackbarDefault({
                message: "UPLOAD_FAILED",
                color: "error",
              });
              this.pageLoading = false;
              this.resetPageData();
              reject(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                this.data[dataKey] = url;
                resolve();
              });
            }
          );
        });
      };

      if (this.image) {
        const imageUpload = handleUpload(
          this.image,
          `customer_logos/${this.company.main_domain}`,
          "logo"
        );
        uploadPromises.push(imageUpload);
      }

      if (this.backgroundImageFile) {
        const backgroundUpload = handleUpload(
          this.backgroundImageFile,
          `customer_backgrounds/${this.company.main_domain}`,
          "background_image"
        );
        uploadPromises.push(backgroundUpload);
      }

      Promise.all(uploadPromises)
        .then(() => {
          this.updateCompany();
        })
        .catch((error) => {
          console.error("One or more uploads failed: ", error);
        })
        .finally(() => {
          this.pageLoading = false;
          this.showConfirmDialog = false;
        });
    },

    updateCompany() {
      const url = "/companies/customize-login";

      this.$axios
        .patch(url, this.data)
        .then(({ data }) => {
          this.setSnackbarDefault({
            message: this.snackbarCode,
          });
          this.setNewCompanyValue(data);

          if (this.userHasAlreadySentFeedback) {
            this.feedbackDialog = true;
          }
        })
        .catch((error) => {
          console.error(error);
          this.setSnackbarDefault({
            message: error.response.data.code,
            color: "error",
          });
        })
        .finally(() => {
          this.resetPageData();
          this.pageLoading = false;
          this.showConfirmRemoveBackgroundDialog = false;
        });
    },

    save() {
      this.snackbarCode = "customize_login_success";

      if (this.image || this.backgroundImageFile) {
        if (
          (this.company.logo && this.image) ||
          (this.company.background_image && this.backgroundImageFile)
        ) {
          this.showConfirmDialog = true;
          return;
        }

        this.uploadFile();

        return;
      }

      this.pageLoading = true;

      this.updateCompany(this.payload);
    },

    resetPageData() {
      this.file = null;
      this.image = null;
      this.backgroundImageFile = null;
      this.showConfirmDialog = false;
      this.showConfirmDialogBackground = false;
      this.pageLoading = false;
      this.initCompanyValues();
    },

    setPrimaryColor(color) {
      this.primaryColor = color;
      this.data.primary_color = color;
    },

    setTextColor(color) {
      this.textColor = color;
      this.data.text_color = color;
    },

    setSecondaryColor(color) {
      this.secondaryColor = color;
      this.data.secondary_color = color;
    },

    setDefaultPrimaryColor() {
      this.primaryColor = DEFAULT_SSO_PRIMARY_COLOR;
      this.data.primary_color = DEFAULT_SSO_PRIMARY_COLOR;
    },

    setDefaultSecondaryColor() {
      this.secondaryColor = DEFAULT_SSO_SECONDARY_COLOR;
      this.data.secondary_color = DEFAULT_SSO_SECONDARY_COLOR;
    },

    setDefaultTextColor() {
      this.textColor = DEFAULT_SSO_TEXT_COLOR;
      this.data.secondary_color = DEFAULT_SSO_TEXT_COLOR;
    },

    initCompanyValues() {
      this.primaryColor = this.company.primary_color;
      this.secondary = this.company.secondary_color;
      this.textColor = this.company.text_color;
      this.logoHeight = this.company.logo_height;
      this.logoWidth = this.company.logo_width;
    },
  },

  watch: {
    file(value) {
      this.image = value;
      if (!value) {
        this.logoHeight = this.company.logo_height;
        this.logoWidth = this.company.logo_width;
      }

      this.forceUpdate();
    },
    backgroundImageFile(value) {
      if (value) {
        this.forceUpdate();
      }
    },
    company() {
      this.initCompanyValues();
    },
    feedbackDialog(value) {
      if (!value && !this.userHasAlreadySentFeedback) {
        setFirebaseEvent(
          this.mainDomain,
          "feedback_not_sent",
          "after_customize_login",
          this.currentUser.email
        );
      }
    },
  },

  created() {
    this.initCompanyValues();
  },
};
</script>
